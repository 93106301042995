import axios from 'axios';
import router from '../router/index.js'
import { ElMessage } from 'element-plus';

axios.defaults.baseURL = 'https://api.nxqlhhk.top';

const api = {
  get(url, param, headers) {
    return new Promise((resolve, reject) => {
      axios.get(url, { params: param, headers: headers }).then(response => resolve(response.data)).catch(error => reject(error));
    });
  },

  post(url, data, headers) {
    return new Promise((resolve, reject) => {
      axios.post(url, data, {
        headers: headers
      }).then(response => resolve(response.data)).catch(error => reject(error));
    });
  }
};
axios.interceptors.request.use((config) => {
  console.log('configconfig', config);
  dealToken(config);
  if (config.method === 'post') {
    config.headers['Content-Type'] = 'application/json'
    config.data = JSON.stringify(config.data);
  }
  console.log('request:', config);
  return config;
}, (error) => {
  console.log('错误的传参')
  return Promise.reject(error);
});
axios.interceptors.response.use((res) => {
  console.log('response:', res.data);
  if (res.data.code !== 200 || res.data.code == '40001' || res.data.code == '40000' || res.data.code == '40002') {
    topMsg(res.data.errorMsg);
    router.push({ path: '/' });
    return;
  }
  return res;
}, (error) => {
  console.log('错误的响应',error)
  topMsg(error.message);
  return Promise.reject(error);
})

function topMsg(text) {
  ElMessage({
    showClose: true,
    message: text,
    center: true,
    type: 'error'
  })

}
function dealToken(config) {
  let url = config.url;
  if (url.indexOf("v4") !== -1) {
    // 设置
    var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdXRoVXNlciI6eyJtZkF1dGhJZCI6ImRhNzYxNWUwNGRkMWQyY2JmZGQzYzRiNjg3ODczOWFiIiwiYXZhdGFyIjoiaHR0cHM6Ly9zdGF0aWMtbGVnYWN5LmRpbmd0YWxrLmNvbS9tZWRpYS9sUURQTTRtVDZkMWtzWG5OQTZ6TkE2eXdSdURlaDZnMU9Pc0dGX1dSMkNVX0FBXzk0MF85NDAuanBnIiwidXNlck5hbWUiOiLmooHlk7IiLCJsb2dpbk5hbWUiOiJ6aGUubGlhbmdAbW9yZWZ1bi5tZSIsInJvbGVzIjpbeyJjb2RlIjoidHJhY2tBZG1pbiIsIm5hbWUiOiLlkI7lj7DnrqHnkIblkZgiLCJyZW1hcmsiOiLlkI7lj7DnrqHnkIblkZgs6K-l6KeS6Imy5Y-v5Lul6K6_6Zeu5Z-L54K55omA5pyJ55qE6LWE5rqQIiwicm9sZUV4dCI6IntcImFjY2Vzc1wiOlwiKlwifSJ9XX19.LqTthnyml45I5zD76Qmtp92k3FtB-bnOlPOSPtNEE20";
    config.headers.Authorization = token;
  }
}

export default api;