<template>
  <el-table v-loading="loading" :height="props.height" class="main-container table-container" :data="tableData" highlight-current-row style="width: 100%">
    <el-table-column fixed prop="id" label="id" width="50" />
    <el-table-column prop="title" label="模板名称" width="330" />
    <el-table-column prop="type" label="模板类型" width="100" />
    <el-table-column prop="status" label="任务状态" width="100">
      <template #default={row}>
        <el-tag v-if="row.status === 'finished'" type="success">运行完成</el-tag>
        <el-tag v-else type="warning">运行中</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="reportUrl" label="上次运行报表下载地址" width="800" />
    <el-table-column fixed="right" label="操作" width="250">
      <template #default={row}>
        <el-button class="report-button" link type="primary" size="mini" @click="handleClick(row)">
          运行报表
        </el-button>
        <el-button class="report-button" link type="primary" size="mini" @click="getTemplateReportList">刷新状态</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-drawer :open="open()" :closed="closeDialog()" v-model="dialog" destroy-on-close title="报表运行日期" direction="rtl" class="demo-drawer">
    <div class="demo-drawer__content">
      <div class="demo-drawer__footer">
        <el-date-picker v-model="selectReportTime" type="daterange" value-format="YYYY-MM-DD" unlink-panels range-separator="To" start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" />
        <el-divider />
        <el-button @click="selectDialogCancel">取消</el-button>
        <el-button type="primary" @click="selectDialogSubmit">
          运行
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps({
  height: String
});
import { ref, onMounted, getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus'
//获取全局
const { appContext } = getCurrentInstance();
const api = appContext.config.globalProperties.$api
// 定义响应式数据
const tableData = ref([])
const loading = ref(false)
const dialog = ref(false);
const selectReportTime = ref([])
//存储弹窗当前row信息
const selectReportNowRow = ref()
const shortcuts = [
  {
    text: '最近一周',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: '最近一月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '最近3个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
]

onMounted(() => {
  console.log('onMouted.....')
  getTemplateReportList();
  // api.get();
})
function open() {
  console.log('open....')
}

function msg(text, type) {
  if (type) {
    ElMessage({
      message: text,
      type: type,
    })
  } else {
    ElMessage({
      message: text,
      type: 'success',
    })
  }
}

async function handleClick(row) {
  // console.log('操作行：', row)
  //打开弹窗
  dialog.value = true;
  //开始运行
  selectReportNowRow.value = row;
  console.log('selectReportNowRow', selectReportNowRow)

}
async function runReportApi(row, startTime, endTime) {
  console.log('开始运行报表：', row)
  console.log('开始运行报表：', row.id, startTime, endTime);
  let url = '/v3/admin/v1/template/start/' + row.id;
  let query = {
    "startDay": startTime,
    "endDay": endTime
  }
  let result = await api.get(url, query);
  if (result && result.success) {
    msg(result.data, 'success');
    result.data.data;
  } else {
    msg(result.errorMsg, 'warning');
  }
}
function selectDialogCancel() {
  dialog.value = false;
  getTemplateReportList();
}

async function selectDialogSubmit() {
  //现在选择的运行时间
  if (!selectReportTime.value || !selectReportTime.value[0] || !selectReportTime.value[0]) {
    // 日期未选择
    msg('未选择报表日期，请重新选择', 'warning');
    return;
  }
  console.log('selectReportTime', selectReportTime.value, selectReportTime.value[0], selectReportTime.value[1]);
  await runReportApi(selectReportNowRow.value, selectReportTime.value[0], selectReportTime.value[1]);
  dialog.value = false;
}
function closeDialog() {
  //取消选择的日期和row
  console.log(`关闭或者取消弹窗`)

  selectReportTime.value = ['', ''];
}


async function getTemplateReportList() {
  loading.value = true
  let url = '/v3/admin/v1/template/list?page=1&pageSize=9999';
  let query = {
  //  "title": '阿伟'
  }
  let result;
  try {
    result = await api.post(url, query);
    if (result && result.success) {
      tableData.value = result.data.data;
    }
  } catch (e) {
    console.log(e);
  }
  console.log(result)
  loading.value = false;
}


</script>

<style scoped>
.report-button {
  margin-left: 0px !important;
  margin-right: 0px !important;
  border: 0px;
  padding: 0px !important;
}
</style>