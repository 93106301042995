<template>
  <div>
    <el-watermark :font="font" :content="['Beta 版本']">
      <div class="main-container">
        <div>

        </div>
        <div class="search">
          <el-date-picker @change="selectImportTime" value-format="YYYY-MM-DD" format="YYYY/MM/DD" v-model="importTimeValue" clearable type="daterange" range-separator="To" start-placeholder="导出开始时间" end-placeholder="导出结束时间" />
          <button class="send-button" @click="importChannel">导出渠道流量</button>
          <el-input @change="getProjectList" v-model="searchName" class="search-input" placeholder="请输入code或项目名" clearable />
          <button @click="getProjectList" class="search-button">搜索一下</button>
        </div>

        <div class="content">
          <el-radio-group v-model=selectProject @change=selectProjectFun>
            <div v-for="item in projectList" :key="item.id" class="content-checkbox">
              <el-radio class="content-checkbox-radio" :value=item.id border>
                <template #default>
                  <el-text truncated tag="p">{{ item.name }}</el-text>
                  <el-text tag="p">—————————————</el-text>
                  <el-text tag="p">项目Code{{ item.code }}</el-text>
                  <el-text tag="p">昨日PV：{{ item.pv }}</el-text>
                  <el-text tag="p">昨日UV：{{ item.uv }}</el-text>
                  <el-text tag="p">埋点版本：{{ item.version }}</el-text>
                </template>
              </el-radio>
            </div>
          </el-radio-group>
        </div>
        <div class="report-content">
          <AiChatBox :messages="chatMessages" :responseDelay="1000" add-message="addMessage" />
        </div>
      </div>
    </el-watermark>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import { fetchEventSource } from '@microsoft/fetch-event-source';
const font = reactive({
  color: 'rgba(0, 0, 0, .15)',
})
import AiChatBox from '../../components/ChatBox.vue';


//获取全局
const { appContext } = getCurrentInstance();
const api = appContext.config.globalProperties.$api
// import projectListMock from '../../mork/projectList'
const projectList = ref([]);
// 单选项目
const selectProject = ref();
// 搜索框
const searchName = ref('');
const importTimeValue = ref();
onMounted(() => {
  console.log('onMouted.....');
  console.log(api);
  // msg('onMount');
  init();
});
const chatMessages = ref([
  { text: 'Hello, how can I help you today?', type: 'ai' }
]);

const addMessage = (msgPre, message) => {
  message += "";
  if (message && message.trim() !== '') {
    let msg = { text: msgPre + message, type: 'ai' };
    chatMessages.value.push(msg);
  }
};
async function init() {
  await getProjectList();
}


async function getProjectList() {
  addMessage("搜索：", searchName.value);
  console.log('searchName:', searchName.value)
  let url = "/v4/tracking-databoard/api/project/pageQuery";
  let query = {
    "name": searchName.value,
    "page": 1,
    "size": 20
  }
  let res;
  try {
    res = await api.post(url, query);
    console.log('res:', res);
  } catch (e) {
    console.log(e);
    return;
  }
  // result = projectListMock;
  if (res && res.success) {
    projectList.value = res.data.list;
  } else {
    msg('获取项目列表失败', 'error');
  }
  console.log('projectList', projectList)
}

async function getChannelByProjectId(projectId, startTime, endTime) {
  let url = "/v4/tracking-databoard/api/module/custom/channelList";
  let query = {
    "projectId": projectId,
    "startTime": startTime,
    "endTime": endTime
  }
  let res;
  try {
    res = await api.post(url, query);
    console.log('getChannelByProjectId:', res);
  } catch (e) {
    console.log(e);
    return;
  }
  if (res && res.success) {
    return res.data.sort();
  }
}
function selectProjectFun(value) {
  selectProject.value = value;
  let _project = findProjectById(value);
  let _msg = `Code：${_project.code}，名称：${_project.name}，版本：${_project.version}`;
  addMessage("项目：", _msg);
}
function selectImportTime() {
  addMessage("选择时间：", importTimeValue.value);
}
// async function importChannelFlowSource(projectCode, version, startDay, endDay, channelList) {
//   let url = "https://dataworks-api.morefun.me/admin/api/v1/report/run/sse";
//   let query = {
//     "projectCode": projectCode,
//     "startDay": startDay,
//     "endDay": endDay,
//     "channelList": channelList,
//     "version": version.toUpperCase(),
//     "typeList": ["CHANNEL_FLOW_SOURCE"]
//   }
//   let res;
//   try {
//     res = await api.post(url, query);
//     console.log('res:', res);
//     addMessage("导出结果", res);
//   } catch (e) {
//     console.log(e);
//   }
// }

async function importChannelFlowSource(projectCode, version, startDay, endDay, channelList) {
  const url = "https://dataworks-api.morefun.me/admin/api/v1/report/run/sse";
  const body = {
    "projectCode": projectCode,
    "startDay": startDay,
    "endDay": endDay,
    "channelList": channelList,
    "version": version.toUpperCase(),
    "typeList": ["CHANNEL_FLOW_SOURCE"]
  };
  const ctrl = new AbortController();
  fetchEventSource(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
    signal: ctrl.signal,
    onopen(response) {
      // 成功连接时回调
      console.log('连接成功', response);
      // addMessage("连接成功", response);
    },
    onmessage(msg) {
      // 服务器返回消息回调 返回{ data,event,id,retry } ，data即服务器返回数据
      console.log('msgmsg', msg)
      if (msg.event == '') {
        // 进行连接正常的操作流程
        let result = msg.data;
        // 将需要展示的数据更新到vue的data中,当然如下this.showData肯定赋值不上，需要将this 指向一个变量，用变量接受数据即可，以下只是展示
        addMessage("\t", result);
      }
      if (msg.event === 'close') {
        addMessage("导出完成", '.....');
        ctrl.abort()
      }
    },
    onerror(err) {
      console.log('err', err);
    }
  });

}
function findProjectById(id) {
  let list = projectList.value;
  return list.find(t => t.id === id);
}

async function importChannel() {
  const _project = findProjectById(selectProject.value);
  if (!_project) {
    // addMessage("项目不存在，", "请选择项目");
    msg("项目不存在，请选择项目", "error");
    return;
  }
  // let _project = list.find(t => { return t.id === selectProject.value })
  addMessage("开始导出渠道数据:", _project.name);
  if (!importTimeValue.value) {
    addMessage("导出日期不存在，", "请选择日期");
    msg("导出日期不存在，请选择日期", "error");
    return;
  }
  let startTime = importTimeValue.value[0];
  let endTime = importTimeValue.value[1];

  addMessage("导出时间:", startTime + ' To ' + endTime);
  addMessage("查询渠道数据:", _project.name);

  let channelList = await getChannelByProjectId(_project.id, startTime, endTime);
  addMessage("渠道数据:", channelList);
  if (!channelList || channelList.length < 1) {
    addMessage("指定日期内渠道无数据:", "");
    msg("指定日期内渠道无数据", "warning");
    return;
  }
  await importChannelFlowSource(_project.code, _project.version, startTime, endTime, channelList);
  // addMessage("导出完成", "。。。。");

}


function msg(text, type) {
  if (type) {
    ElMessage({
      message: text,
      type: type,
    })
  } else {
    ElMessage({
      message: text,
      type: 'success',
    })
  }
}

</script>

<style scoped>
/* 去掉 el-radio 前面的小圆圈 */
::v-deep .el-radio__input {
  display: none;
}
::v-deep .el-radio__label {
  width: 200px;
}
.content {
  font-size: 12px;
  display: flex;
  min-width: 1200px;
  min-height: 300px;
  max-width: 1200px;
  max-height: 300px;
  position: relative;
  overflow: auto;
  margin-top: 10px;
  margin-left: 20px;
  flex-wrap: wrap;
}
.content-checkbox {
  width: 220px;
  height: 130px;
  margin: 10px;
  overflow: auto;
}
.content-checkbox-radio {
  height: 130px;
  width: 220px;
  /* 文字靠左 */
  text-align: left;
  background-color: #f5f5f5;
  border-radius: 5px;
}
.search {
  display: flex;
  align-items: center;
  width: 100%; /* 根据需要调整宽度 */
  max-width: 1000px; /* 最大宽度，类似于百度搜索框 */
  height: 40px; /* 高度 */
  margin: 0 auto; /* 水平居中 */
  position: relative;
}
.search-input {
  width: 70%; /* 占满整个容器宽度 */
  height: 100%; /* 占满整个容器高度 */
  padding: 0 20px; /* 内边距 */
  font-size: 16px; /* 字体大小 */
  /* border: 2px solid #ccc; 边框 */
  /* border-radius: 20px; 圆角 */
  outline: none; /* 去除焦点时的轮廓线 */
  margin-left: 30px;
}
.search-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px; /* 按钮宽度 */
  height: 100%;
  background-color: #38f; /* 按钮背景色 */
  color: white;
  border: none;
  border-radius: 0 20px 20px 0; /* 圆角 */
  cursor: pointer;
  font-size: 16px;
}

.search-button:hover {
  background-color: #2d78f4; /* 鼠标悬浮时的背景色 */
}

.send-button {
  margin-left: 20px;
  width: 140px;
  height: 40px;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
</style>

