<template>
  <div class="ai-chat-box">
    <div class="chat-header">Chat Box</div>
    <div class="chat-body" ref="chatBody">
      <ul>
        <li v-for="(message, index) in messages" :key="index" :class="{ 'user-message': message.type === 'user', 'ai-message': message.type === 'ai' }">
          {{ message.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, nextTick } from 'vue';

const props = defineProps({
  messages: {
    type: Array,
    default: () => [],
  },
  responseDelay: {
    type: Number,
    default: 1000,
  },
});
const chatBody = ref(null);

// 滚动到底部的方法
const scrollToBottom = async () => {
  await nextTick(); // 等待 Vue 更新 DOM
  const chatBodyElement = chatBody.value;
  if (chatBodyElement) {
    chatBodyElement.scrollTop = chatBodyElement.scrollHeight;
  }
};

// 使用 watch 侦听 messages 数组的变化
watch(() => props.messages, () => {
  scrollToBottom();
}, {
  deep: true,
});
</script>
  



  <style scoped>
.ai-chat-box {
  max-width: 1200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 20px 20px;
  padding: 10px;
  overflow: hidden;
}

.chat-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.chat-body {
  height: 200px;
  overflow-y: auto;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  padding: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  font-size: 16px;
}

.user-message {
  text-align: right;
  color: #888;
}

.ai-message {
  text-align: left;
  color: #333;
}

.input-message {
  width: calc(100% - 90px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.send-button {
  width: 80px;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}
</style>