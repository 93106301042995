<template>
  <div class="home">
    <div>
      <el-watermark :font="font" :content="['阿伟专用']">
        <TemplateReport :height="660" ></TemplateReport>
      </el-watermark>
    </div>
  </div>
</template>

<script setup>
import TemplateReport from '@/components/TemplateReport.vue';
import { reactive } from 'vue'
const font = reactive({
  color: 'rgba(0, 0, 0, .15)',
})
</script>

<style scoped>
.home {
  position: absolute;
  top: 10%;
  width: 80%;
  height: 90%;
}
</style>
